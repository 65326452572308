import React, { useEffect, useMemo, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import TableContainerReverseSort from '../table/TableContainerReverseSort';
import NewEntry from './NewEntry';
import Moment from 'react-moment';
import LicenseLookup from '../modals/LicenseLookup';

const ViewLog = ({ socket, currentUser, activeLog, setActiveLog, activeUsers }) => {
    const [loading, setLoading] = useState(true);
    const [contacts, setContacts] = useState([]);
    const [showDecision, setShowDecision] = useState(false);

    useEffect(() => {
        const handleLogLoaded = data => {
            setContacts(data.contacts);
            setLoading(false);
        };

        const handleContactReceived = data => {
            if (data.logId === activeLog.data._id) {
                setContacts(prevContacts => [...prevContacts, data]);
            }
        };

        socket.on('log_loaded', handleLogLoaded);
        socket.on('contact_received', handleContactReceived);

        if (loading) {
            socket.emit('load_log', { id: activeLog.data._id, userId: currentUser.user._id });
        }

        return () => {
            socket.off('log_loaded', handleLogLoaded);
            socket.off('contact_received', handleContactReceived);
        };
    }, [loading, currentUser.user._id, activeLog.data._id, socket]);

    const columns = useMemo(() => [
        {
            Header: 'Log ID',
            accessor: '',
            disableFilters: false,
            descending: true,
            Cell: e => e.row.index + 1
        },
        {
            Header: 'Timestamp',
            accessor: 'createdAt',
            disableFilters: false,
            descending: true,
            Cell: e => <Moment format='MM/DD/YY HH:mm:ss'>{e.value}</Moment>
        },
        {
            Header: 'Callsign',
            accessor: 'call',
            disableFilters: false,
            descending: true,
            Cell: e => e.row.original.license ? (
                <LicenseLookup
                    socket={socket}
                    callsignColor={e.row.original.license.status !== 'A' ? 'red' : 'white'}
                    callsign={e.row.original.license.call || "NOT_FOUND"}
                />
            ) : 'N/A'
        },
        {
            Header: 'Name',
            accessor: 'fname',
            disableFilters: false,
            descending: true,
            Cell: e => {
                const fname = e.row.original.license ? e.row.original.license.fname : "NOT_FOUND";
                const formattedFname = fname.charAt(0).toUpperCase() + fname.slice(1).toLowerCase();
                return <span>{formattedFname}</span>;
            }
        },
        {
            Header: 'Location',
            accessor: 'addr2',
            disableFilters: false,
            descending: true,
            Cell: e => e.row.original.license ? (
                <div>
                    <div>{e.row.original.license.addr2.toUpperCase()}, {e.row.original.license.state.toUpperCase()},</div>
                    <div>{e.row.original.license.country.toUpperCase()}</div>
                </div>
            ) : <div>NOT_FOUND</div>
        },
        {
            Header: 'Frequency',
            accessor: 'frequency',
            disableFilters: false,
            descending: true,
        },
        {
            Header: 'Band',
            accessor: 'band',
            disableFilters: false,
            descending: true,
        },
        {
            Header: 'Mode',
            accessor: 'mode',
            disableFilters: false,
            descending: true,
        },
        {
            Header: 'Notes',
            accessor: 'notes',
            disableFilters: false,
            descending: true,
            Cell: e => (
                <div style={{ display: 'flex', width: '400px', maxHeight: '72px', overflow: 'hidden' }}>
                    <span style={{ width: '100%', overflowY: 'auto', wordWrap: 'break-word' }}>
                        {e.value}
                    </span>
                </div>
            )
        }
    ], [socket]);

    return (
        <Modal className='text-white' show={activeLog.show} fullscreen={true} onHide={() => setActiveLog({ ...activeLog, show: false })}>
            <Modal.Header closeButton>
                <Container fluid>
                    <Modal.Title>{activeLog.data.name}</Modal.Title>
                </Container>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <NewEntry 
                            socket={socket}
                            activeNet={activeLog}
                            currentUser={currentUser}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TableContainerReverseSort
                            columns={columns}
                            data={contacts}
                            tableColor={'dark'}
                        />
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default ViewLog;
