import React, { useEffect, useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import AccountMenu from '../user/AccountMenu';
import AppMenu from './AppMenu';
import ChatModal from '../modals/Chat';
import Logo from '../pieces/Logo';

const FileBar = ({ currentUser, setCurrentUser, handleLogout, activeUsers, setActiveUsers, nets, setNets, socket }) => {
    const [messageLoad, setMessageLoad] = useState(false);
    const [messages, setMessages] = useState([]);
    const [menuOpen, setMenuOpen] = useState(false);
    const [accountOpen, setAccountOpen] = useState(false);
    const [chatOpen, setChatOpen] = useState(false);
    const [unreadMessages, setUnreadMessages] = useState(false); // New state for unread messages
    const [unreadUsers, setUnreadUsers] = useState({}); // New state to track unread messages per user
    const [menuPage, setMenuPage] = useState();

    const handleFolderMenu = (e) => {
        setMenuPage(e);
        setMenuOpen(!menuOpen);
    }

    const handleAccountMenu = () => {
        setAccountOpen(!accountOpen);
    }

    const handleChatMenu = () => {
        setChatOpen(!chatOpen);
        if (unreadMessages) {
            setUnreadMessages(false); // Reset unread messages when chat is opened
        }
        setUnreadUsers({}); // Reset unread messages per user when chat is opened
    }

    useEffect(() => {
        const handleMessagesLoaded = (data) => {
            setMessages(data);
            setMessageLoad(false);
        };

        const handleReceiveMessage = (data) => {
            if (data.toCall === currentUser.user.username) {
                setMessages((prevMessages) => [...prevMessages, { fromCall: data.fromCall, text: data.text }]);
                if (!chatOpen) {
                    setUnreadMessages(true); // Set unread messages if chat is not open
                    setUnreadUsers((prevUnreadUsers) => ({
                        ...prevUnreadUsers,
                        [data.fromCall]: true // Mark the sender as having unread messages
                    }));
                }
            }
        };

        socket.on('messages_loaded', handleMessagesLoaded);
        socket.on('receive_message', handleReceiveMessage);

        return () => {
            socket.off('messages_loaded', handleMessagesLoaded);
            socket.off('receive_message', handleReceiveMessage);
        };
    }, [currentUser.user.username, chatOpen, socket]);

    return (
        <Navbar expand="lg" bg="primary" data-bs-theme="dark">
            <Container fluid>
                <Navbar.Brand href="">
                    <Logo height={40} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        {currentUser.isAuthenticated && (
                            <>
                                <Nav.Link active onClick={() => handleFolderMenu(2)}>My Nets</Nav.Link>
                                <Nav.Link active onClick={() => handleFolderMenu(3)}>My Logs</Nav.Link>
                                <Nav.Link active onClick={() => handleFolderMenu(5)}>Help</Nav.Link>
                            </>
                        )}
                    </Nav>
                    <Nav className="ms-auto">
                        {currentUser.isAuthenticated ? (
                            <Nav.Link active onClick={handleAccountMenu}>{currentUser.user.username}</Nav.Link>
                        ) : (
                            <Nav.Link active onClick={handleAccountMenu}>Login</Nav.Link>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Container>
            { (currentUser.isAuthenticated && menuOpen) && (
                <>
                    <AppMenu 
                        socket={socket}
                        currentUser={currentUser}
                        nets={nets}
                        setNets={setNets}
                        handleFolderMenu={handleFolderMenu}
                        activeUsers={activeUsers}
                        menuPage={menuPage}
                    />
                </>
            )}
            <AccountMenu 
                show={accountOpen}
                setShow={setAccountOpen}
                currentUser={currentUser}
                setCurrentUser={setCurrentUser}
                handleLogout={handleLogout}
                menuOpen={menuOpen}
                setMenuOpen={setMenuOpen}
                socket={socket}
            />
        </Navbar>
    );
};

export default FileBar;
