import React from "react"
import { InputGroup, Form } from "react-bootstrap"

export const Filter = ({ column }) => {
    return (
        <div style={{ marginTop: 5 }}>
            {column.canFilter && column.render("Filter")}
        </div>
    )
}

export const DefaultColumnFilter = ({
    column: {
        filterValue,
        setFilter,
        preFilteredRows: { length },
    },
  }) => {
    return (
        <InputGroup className="mb-3">
                <Form.Control
                    placeholder="Search..."
                    aria-label="search"
                    aria-describedby="search1"
                    value={filterValue || ""}
                    onChange={e => {
                        setFilter(e.target.value || undefined)
                    }}
                />
        </InputGroup>
    )
}

// export const SelectColumnFilter = ({
// column: { filterValue, setFilter, preFilteredRows, id },
// }) => {
// const options = React.useMemo(() => {
//     const options = new Set()
//     preFilteredRows.forEach(row => {
//     options.add(row.values[id])
//     })
//     return [...options.values()]
// }, [id, preFilteredRows])

// return (
//     <CustomInput
//         id="custom-select"
//         type="select"
//         value={filterValue}
//         onChange={e => {
//             setFilter(e.target.value || undefined)
//         }}
//     >
//     <option value="">All</option>
//     {options.map(option => (
//         <option key={option} value={option}>
//         {option}
//         </option>
//     ))}
//     </CustomInput>
// )
// }