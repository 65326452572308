import React, { useState } from 'react';
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import Select from 'react-select';

const NewLog = ({ currentUser, socket }) => {
    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        creator: currentUser.user._id,
        type: ''
    });

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSelectChange = (selectedOption) => {
        setFormData({ ...formData, type: selectedOption.value });
    };

    const handleNewLog = () => {
        socket.emit('create_log', formData);
        handleClose();
    };

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                New Log
            </Button>

            <Modal className='text-white' show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>New Log</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col>
                                <InputGroup className="mb-3 shadow">
                                    <InputGroup.Text id="basic-addon1">Log Name</InputGroup.Text>
                                    <Form.Control
                                        name="name"
                                        onChange={handleInputChange}
                                        aria-label="Log Name"
                                        aria-describedby="basic-addon1"
                                        required
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Select
                                    className="text-black shadow"
                                    placeholder="Log Type"
                                    required
                                    onChange={handleSelectChange}
                                    options={[
                                        { value: 'Personal Log', label: 'Personal Log' },
                                        { value: 'Field Day', label: 'Field Day' },
                                    ]}
                                />
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Row className="mt-3">
                        <Col>
                            <Button onClick={handleNewLog} type="button" className="btn-block shadow">
                                Create Log
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default NewLog;
