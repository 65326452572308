import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import bandsAndFreqs from '../../bandsAndFreqs.json';

const NewEntry = ({ socket, currentUser, activeNet }) => {
    const [formData, setFormData] = useState({
        callsign: "",
        band: "",
        mode: "",
        frequency: "",
        power: "",
        time: "",
        notes: "",
    });

    const availableModes = bandsAndFreqs.ham_radio_bands.find(band => band.band === formData.band)?.modes_allowed || [];
    const logId = activeNet.data._id;
    const callsignRef = useRef(null);

    const handleSubmit = (e) => {
        if (formData.callsign && formData.band && formData.frequency && formData.mode && formData.power) {
            e.preventDefault();
            socket.emit('new_contact', { ...formData, logId, loggerCallsign: currentUser.user.username });
            setFormData({
                ...formData,
                callsign: "",
                time: "",
                notes: "",
            });
            callsignRef.current.focus();
        } else {
            alert("Please fill in all required fields.");
        }
    }

    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                handleSubmit(event);
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [formData]);

    useEffect(() => {
        callsignRef.current.focus();
    }, []);

    const findBandByFrequency = (frequency) => {
        return bandsAndFreqs.ham_radio_bands.find(band => {
            const [start, end] = band.frequency_range.split('-').map(Number);
            return frequency >= start && frequency <= end;
        })?.band || "";
    };

    const handleFormData = e => {
        const { name, value } = e.target;
        let newFormData = { ...formData, [name]: value };

        if (name === 'frequency') {
            const frequency = parseFloat(value);
            if (!isNaN(frequency)) {
                const band = findBandByFrequency(frequency);
                newFormData = { ...newFormData, band };
            }
        }

        setFormData(newFormData);
    };

    return (
        <Form onSubmit={handleSubmit} className="d-flex justify-content-center align-items-center mb-2">
            <Row>
                <Col xs="auto">
                    <Form.Group controlId="formCallsign" className='mb-1'>
                        <Form.Label srOnly>
                            <span>Callsign</span>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={formData.callsign.toUpperCase()}
                            name='callsign'
                            onChange={handleFormData}
                            required
                            ref={callsignRef}
                        />
                    </Form.Group>
                </Col>
                <Col xs="auto">
                    <Form.Group controlId="formFrequency" className='mb-1'>
                        <Form.Label srOnly>
                            Frequency
                        </Form.Label>
                        <Form.Control
                            type="number"
                            step={0.0001}
                            value={formData.frequency}
                            name='frequency'
                            onChange={handleFormData}
                            required
                        />
                    </Form.Group>
                </Col>
                <Col xs="auto">
                    <Form.Group controlId="formBand" className='mb-1'>
                        <Form.Label srOnly>
                            Band
                        </Form.Label>
                        <Select
                            className='text-black shadow'
                            value={{ label: formData.band, value: formData.band }}
                            onChange={e => setFormData({ ...formData, band: e.value })}
                            options={bandsAndFreqs.ham_radio_bands.map(band => ({
                                label: band.band,
                                value: band.band
                            }))}
                            isDisabled
                        />
                    </Form.Group>
                </Col>
                <Col xs="auto">
                    <Form.Group controlId="formMode" className='mb-1'>
                        <Form.Label srOnly>
                            Mode
                        </Form.Label>
                        <Select
                            className='text-black shadow'
                            onChange={e => setFormData({ ...formData, mode: e.value })}
                            required
                            options={availableModes.map(mode => ({
                                label: mode,
                                value: mode
                            }))}
                        />
                    </Form.Group>
                </Col>
                <Col xs="auto">
                    <Form.Group controlId="formPower" className='mb-1'>
                        <Form.Label srOnly>
                            RF Power
                        </Form.Label>
                        <Form.Control
                            type="number"
                            value={formData.power}
                            name='power'
                            onChange={handleFormData}
                            required
                        />
                    </Form.Group>
                </Col>
                <Col xs="auto">
                    <Form.Group controlId="formNotes">
                        <Form.Label srOnly>Notes</Form.Label>
                        <Form.Control
                            as="textarea"
                            value={formData.notes}
                            name='notes'
                            onChange={handleFormData}
                            cols={20}
                            rows={1}
                        />
                    </Form.Group>
                </Col>
            </Row>
        </Form>
    );
};

export default NewEntry;
