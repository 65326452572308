
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import UserMenu from '../pieces/UserMenu';
import SignUp from './SignUp';
import SignIn from './SignIn';
import Select from 'react-select';

const AccountMenu = ({show, setShow, currentUser, setCurrentUser, handleLogout, socket}) => {

    const [ signUp, setSignUp ] = useState(false);

    const handleClose = () => {
        setShow(false);
    }

    return (
        <Modal className='text-black' size='lg' show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {!currentUser.isAuthenticated ? (
                        <Select 
                            defaultValue={{label: "Sign In", value: false}}
                            options={[
                                { label: "Sign In", value: false },
                                { label: "Sign Up", value: true }
                            ]}
                            onChange={e => setSignUp(e.value)}
                        />
                    ) : (
                        <span>Account Menu</span>
                    )}
                </Modal.Title>
            </Modal.Header>
                <Modal.Body className='modal-body-nofooter'>
                    {currentUser.isAuthenticated ? (
                        <UserMenu 
                            handleLogout={handleLogout}
                            currentUser={currentUser}
                            setCurrentUser={setCurrentUser}
                            socket={socket}
                            handleClose={handleClose}
                        />
                    ) : (
                        signUp ? (
                            <SignUp 
                                show={show}
                                setShow={setShow}
                                currentUser={currentUser}
                                setCurrentUser={setCurrentUser}
                            />
                        ) : (
                            <SignIn 
                                show={show}
                                setShow={setShow}
                                currentUser={currentUser}
                                setCurrentUser={setCurrentUser}
                            />
                        )
                    )}
                </Modal.Body>
        </Modal>
    );
    
};

export default AccountMenu