import React, { useState } from 'react';
import {Button, Modal} from 'react-bootstrap';

const DecisionCheck = ({title, body, handler, setHandler}) => {

    const [ show, setShow ] = useState(false);

    const handleClose = () => {
        setShow(false);
    }

    const handleOpen = () => {
        setShow(true);
    };

    const handleHandler = () => {
        setHandler(!handler);
        setShow(!show);
    }
    
    return (
        <>
            <Button className='shadow width-full' onClick={handleOpen} variant='danger'>{title}</Button>
            <Modal className='text-black' show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{body}</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleHandler}>
                        Yes
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default DecisionCheck;
