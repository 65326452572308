import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Select from "react-select";

const TablePagination = ({
    pageOptions,
    pageCount,
    pageIndex,
    pageSize,
    canPreviousPage,
    previousPage,
    gotoPage,
    nextPage,
    canNextPage,
    onChangeInInput,
    onChangeInSelect
}) => {

    return (
        <div className="pagination mb-3">
            <Container fluid>
            <Row className="" style={{ maxWidth: 1000, margin: "0 auto", textAlign: "center" }}>
                <Col md={6}>
                    <div className="d-flex justify-content-center">
                        <span>Page&nbsp;</span>
                        <input
                            className="form-control mt-1"
                            type="number"
                            min={1}
                            style={{ width: 70, height: 20 }}
                            max={pageOptions.length}
                            value={pageIndex + 1}
                            onChange={onChangeInInput}
                        />
                        <span className="full-width">&nbsp;of {pageOptions.length}</span>
                    </div>
                </Col>
                <Col md={2}></Col>
                <Col md={4}>
                    <div className="d-flex justify-content-center align-items-center">
                        <Select type="select" 
                            className="text-black"
                            placeholder={pageSize}
                            value={pageSize} 
                            onChange={e => onChangeInSelect(e.value)} 
                            options={[
                                {label: 10, value: 10},
                                {label: 25, value: 25},
                                {label: 50, value: 50},
                                {label: 100, value: 100},
                            ]}    
                        />
                        <span>&nbsp;Per Page</span>
                    </div>
                </Col>
            </Row>
            </Container>
        </div>
    )
}

export default TablePagination