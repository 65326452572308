import React, { useEffect, useState, useRef } from 'react';
import { Modal, Button, ListGroup, FormControl, Alert, Container, Row, Col, InputGroup } from 'react-bootstrap';

const ChatModal = ({ preSelectedUser, socket, currentUser, users }) => {
    const [selectedUser, setSelectedUser] = useState(preSelectedUser || null);
    const [inputMessage, setInputMessage] = useState('');
    const [search, setSearch] = useState('');
    const [messageLoad, setMessageLoad] = useState(false);
    const [messages, setMessages] = useState([]);
    const [show, setShow] = useState(false);
    const [unreadMessages, setUnreadMessages] = useState(false);
    const [unreadUsers, setUnreadUsers] = useState({});
    const chatWindowRef = useRef(null);

    const toggleChatMenu = () => {
        setShow(!show);
        if (unreadMessages) setUnreadMessages(false);
        setUnreadUsers({});
    };

    useEffect(() => {
        const handleMessagesLoaded = (data) => {
            setMessages(data);
            setMessageLoad(false);
        };

        const handleReceiveMessage = (data) => {
            if (data.toCall === currentUser.user.username) {
                setMessages((prevMessages) => [...prevMessages, { fromCall: data.fromCall, text: data.text }]);
                if (!show) {
                    setUnreadMessages(true);
                    setUnreadUsers((prevUnreadUsers) => ({
                        ...prevUnreadUsers,
                        [data.fromCall]: true,
                    }));
                }
            }
        };

        socket.on('messages_loaded', handleMessagesLoaded);
        socket.on('receive_message', handleReceiveMessage);

        return () => {
            socket.off('messages_loaded', handleMessagesLoaded);
            socket.off('receive_message', handleReceiveMessage);
        };
    }, [currentUser.user.username, show, socket]);

    const handleUserSelect = (user) => {
        setSelectedUser(user.username);
        setMessageLoad(true);
        setMessages([]);
        setUnreadUsers((prevUnreadUsers) => ({
            ...prevUnreadUsers,
            [user.username]: false,
        }));
    };

    const handleSendMessage = () => {
        if (inputMessage.trim()) {
            setMessages([...messages, { fromCall: currentUser.user.username, text: inputMessage }]);
            socket.emit('send_message', { fromCall: currentUser.user.username, toCall: selectedUser, text: inputMessage });
            setInputMessage('');
        }
    };

    useEffect(() => {
        const chatWindow = chatWindowRef.current;
        if (chatWindow) chatWindow.scrollTop = chatWindow.scrollHeight;
    }, [messages]);

    useEffect(() => {
        if (messageLoad) {
            socket.emit('load_messages', { toCall: selectedUser, fromCall: currentUser.user.username }, (loadedMessages) => {
                setMessages(loadedMessages);
                setMessageLoad(false);
            });
        }
    }, [messageLoad, currentUser.user.username, selectedUser, socket]);

    const filteredUsers = search
        ? users.filter(
              (user) =>
                  user.username !== currentUser.user.username &&
                  user.username.toLowerCase().includes(search.toLowerCase())
          )
        : users.filter((user) => user.username !== currentUser.user.username);

    return (
        <>
            <div className="position-fixed bottom-0 end-0 p-3"  style={{ zIndex: 9999 }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={toggleChatMenu}
                    width="25"
                    height="25"
                    fill="currentColor"
                    className="bi bi-chat-dots"
                    viewBox="0 0 16 16"
                >
                    <path d="M16 8c0 3.866-3.582 7-8 7a9 9 0 0 1-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7M5 8a1 1 0 1 0-2 0 1 1 0 0 0 2 0m4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>
                </svg>
                {unreadMessages && (
                    <span className="position-absolute top-0 start-100 translate-middle p-1 bg-danger border border-light rounded-circle">
                        <span className="visually-hidden">Unread messages</span>
                    </span>
                )}
            </div>
            <Modal className='text-white' show={show} onHide={toggleChatMenu} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Chat</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-footer'>
                    <Container fluid>
                        <Row>
                            <Col md={4}>
                                <Row className="mb-1">
                                    <Col>
                                        <FormControl
                                            placeholder="Search"
                                            value={search}
                                            onChange={(e) => setSearch(e.target.value)}
                                        />
                                    </Col>
                                </Row>
                                <Row className="border message-users">
                                    <Col>
                                        <ListGroup className="pt-2 pb-2">
                                            {filteredUsers.map((user, index) => (
                                                <ListGroup.Item
                                                    key={index}
                                                    active={selectedUser === user.username}
                                                    onClick={() => handleUserSelect(user)}
                                                    className="d-flex justify-content-between align-items-center"
                                                >
                                                    {user.username}
                                                    {unreadUsers[user.username] && (
                                                        <span className="badge bg-danger rounded-pill">●</span>
                                                    )}
                                                </ListGroup.Item>
                                            ))}
                                        </ListGroup>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={8}>
                                <div className="ps-3">
                                    {selectedUser ? (
                                        <>
                                            <h5>Conversation with {selectedUser}</h5>
                                            <div className="messages mt-3 border p-3">
                                                <div
                                                    id="chatWindow"
                                                    ref={chatWindowRef}
                                                    style={{ maxHeight: '400px', overflowY: 'auto' }}
                                                >
                                                    {messages.map((message, index) => (
                                                        <div
                                                            className={`d-flex ${
                                                                message.fromCall === currentUser.user.username
                                                                    ? 'justify-content-end'
                                                                    : 'justify-content-start'
                                                            }`}
                                                            key={index}
                                                        >
                                                            <Alert
                                                                style={{ width: 'max-content' }}
                                                                variant={
                                                                    message.fromCall === currentUser.user.username
                                                                        ? 'primary'
                                                                        : 'secondary'
                                                                }
                                                                className={`message ${
                                                                    message.fromCall === currentUser.user.username
                                                                        ? 'text-end justify-content-end'
                                                                        : 'text-start'
                                                                }`}
                                                            >
                                                                <p className="mb-1">
                                                                    <strong>{message.fromCall}</strong>
                                                                </p>
                                                                <p className="mb-2">{message.text}</p>
                                                            </Alert>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <InputGroup className="mt-3">
                                                <FormControl
                                                    type="text"
                                                    placeholder="Type a message..."
                                                    value={inputMessage}
                                                    onChange={(e) => setInputMessage(e.target.value)}
                                                    onKeyDown={(e) => e.code === 'Enter' && handleSendMessage()}
                                                />
                                                <Button variant="primary" onClick={handleSendMessage}>
                                                    Send
                                                </Button>
                                            </InputGroup>
                                        </>
                                    ) : (
                                        <p>Select a user to start a conversation.</p>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ChatModal;
