import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';

const LicenseLookup = ({ callsign, socket }) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [license, setLicense] = useState({});

  const handleShow = () => {
    setLoading(true);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    if (loading) {
      socket.emit('license_lookup', { callsign });
      socket.on('license_data', data => {
        setLicense(data);
        setLoading(false);
      });

      // Clean up the socket listener when the component unmounts
      return () => {
        socket.off('license_data');
      };
    }
  }, [loading, callsign, socket]);

  console.log(license);

  return (
    <>
      <span
        className='button-link active-cursor'
        onClick={handleShow}
        style={(!loading && license.status && license.status !== "A") ? { color: 'red' } : { color: 'inherit' }}
      >
        {callsign}
      </span>
      <Modal className='text-black' show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{callsign} Lookup</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            "Loading..."
          ) : (
            <>
              <Row>
                <Col>
                  <p className='short-p'>Callsign: {license.call}</p>
                </Col>
                <Col>
                  <p className='short-p'>Name: {license.fname} {license.mi} {license.name}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>Class: {license.class === 'E' ? 'Extra' : license.class === 'G' ? 'General' : license.class === 'T' ? 'Technician' : license.class}</p>
                </Col>
                <Col>
                  <p>Status: {license.status === 'A' ? (<span style={{ color: 'green' }}>Active</span>) : <span style={{ color: 'red' }}>INACTIVE</span>}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className='short-p'>{license.addr1}</p>
                  <p className='short-p'>{license.addr2}, {license.state}</p>
                  <p>{license.zip}</p>
                </Col>
                <Col>
                  <Row>
                    <Col><p className='short-p'>Grid: {license.grid}</p></Col>
                  </Row>
                  <Row>
                    <Col><p className='short-p'>Latitude: {license.lat}</p></Col>
                  </Row>
                  <Row>
                    <Col><p>Longitude: {license.lon}</p></Col>
                  </Row>
                </Col>
              </Row>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LicenseLookup;
