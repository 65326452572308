import React, { act, useEffect, useState } from 'react';
import {Button, Col, Modal, Row, Form} from 'react-bootstrap';
import Select from 'react-select';
import DecisionCheck from '../modals/DecisionCheck';

const NetSettings = ({ currentUser, activeNet, setActiveNet, loading, activeUsers, socket, userAllowed }) => {

    const [ show, setShow ] = useState(false);
    const [showDecision, setShowDecision] = useState(false);
    const [proceedToClose, setProceedToClose] = useState(false);

    const handleClose = () => {
        setShow(false);
    }

    const handleAllowedNCS = e => {
        socket.emit('add_ncs', {allowedNcs: e, netId: activeNet.data._id});
    }

    const handleUpdateNet = e => {
        socket.emit('update_net', {netData: activeNet.data, netId: activeNet.data._id});
    }

    useEffect(() => {
        if (proceedToClose) {
            socket.emit('end_net', activeNet.data._id);
            setProceedToClose(false);
            setActiveNet(prevActiveNet => ({ ...prevActiveNet, show: false }));
        }
    }, [proceedToClose, socket, activeNet.data._id, setActiveNet]);

    useEffect(() => {
        socket.on('net_updated', data => {
            if(data.netId === activeNet.data._id){
                setActiveNet({...activeNet, logger: data.logger, ncs: data.ncs});
            }
        });
    });
    
    return (
        <>
            {(userAllowed()) && (
                <svg onClick={() => setShow(!show)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-gear" viewBox="0 0 16 16">
                    <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0"/>
                    <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115z"/>
                </svg>
            )} 
            <Modal size='lg' className='text-black' show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Net Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <Row>
                            <Col className='mb-3'>
                                <Form.Label>Current NCS</Form.Label>
                                <Select 
                                    className='text-black shadow width-full'
                                    placeholder="Allowed NCSs"
                                    defaultValue={{value: activeNet.data.ncs, label: activeNet.data.ncs}}
                                    required
                                    onChange={e => setActiveNet({...activeNet, data: {...activeNet.data, ncs: e.value}})}
                                    options={
                                        activeUsers.map(u => (
                                            { label: u.username, value: u.username }
                                        ))
                                    }
                                />
                            </Col>
                            <Col className='mb-3'>
                                <Form.Label>Current Logger</Form.Label>
                                <Select 
                                    className='text-black shadow width-full'
                                    placeholder="Allowed NCSs"
                                    defaultValue={{value: activeNet.data.logger, label: activeNet.data.logger}}
                                    required
                                    onChange={e => setActiveNet({...activeNet, data: {...activeNet.data, ncs: e.value}})}
                                    options={
                                        activeUsers.map(u => (
                                            { label: u.username, value: u.username }
                                        ))
                                    }
                                />
                            </Col>
                            <Col>
                                <Form.Label>Allowed NCS</Form.Label>
                                <Select 
                                    className='text-black shadow width-full'
                                    placeholder="Allowed NCSs"
                                    isMulti={true}
                                    defaultValue={activeNet.data.allowedNcs}
                                    required
                                    onChange={e => handleAllowedNCS(e)}
                                    options={
                                        activeUsers.map(u => (
                                            { label: u.username, value: u.username }
                                        ))
                                    }
                                />
                            </Col>
                        </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col>
                            <DecisionCheck
                                title={"Close Net"}
                                body={"Are you sure you wish to close this net?"}
                                show={showDecision}
                                setShow={setShowDecision}
                                handler={proceedToClose}
                                setHandler={setProceedToClose}
                            />
                        </Col>
                        <Col>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default NetSettings;
