import React, { useEffect, useState } from 'react';
import {Button, Col, Modal, Row } from 'react-bootstrap';
import MyNets from '../Nets/MyNets';
import MyLogs from '../Logs/MyLogs';
import Logo from '../pieces/Logo';

const AppMenu = ({handleFolderMenu, socket, currentUser, nets, setNets, menuOpen, setMenuOpen, activeUsers, menuPage}) => {

    const [ loading, setLoading ] = useState(true);

    const defaultNet = {
        name: null,
        ncs: null,
        logger: null,
        freq: null,
        band: null,
        mode: null,
    };

    const [ newNet, setNewNet ] = useState(defaultNet);

    useEffect(() => {
        let page = window.localStorage.getItem('page');
        // if(loading && page){
        //     const pageNumber = parseInt(page, 10);
        //     setMenuPage(pageNumber);
        //     setLoading(false);
        // }
    }, [loading]);

    // const handleMenuPage = (e) => {
    //     window.localStorage.setItem('page', e);
    //     setMenuPage(e);
    // }

    const handleNewNet = e => {
        e.preventDefault();
        socket.emit('activate_net', newNet);
        handleFolderMenu();
    }
    
    return (
        <Modal
            fullscreen={true}
            show={true}
            onHide={handleFolderMenu}
            aria-labelledby="menu-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <Row>
                        <Col>
                            <Logo 
                                height={40}
                            />
                        </Col>
                        {/* <Col>
                            <Button className='btn-block shadow' active={menuPage === 2} style={{width: '120px'}} onClick={() => handleMenuPage(2)}>My Nets</Button>
                        </Col>
                        <Col>
                            <Button className='btn-block shadow' active={menuPage === 3} style={{width: '120px'}} onClick={() => handleMenuPage(3)}>My Logs</Button>
                        </Col>
                        <Col>
                            <Button className='btn-block shadow' active={menuPage === 4} style={{width: '120px'}} onClick={() => handleMenuPage(4)}>My Checkins</Button>
                        </Col>
                        <Col>
                            <Button className='btn-block shadow' active={menuPage === 5} style={{width: '120px'}} onClick={() => handleMenuPage(5)}>Help</Button>
                        </Col> */}
                    </Row>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                
                { menuPage === 2 ? (
                    <MyNets 
                        socket={socket}
                        currentUser={currentUser}
                        nets={nets}
                        setNets={setNets}
                        menuOpen={menuOpen}
                        setMenuOpen={setMenuOpen}
                        handleNewNet={handleNewNet}
                        newNet={newNet}
                        setNewNet={setNewNet}
                    />
                ) : menuPage === 3 ? (
                    <MyLogs 
                        socket={socket}
                        currentUser={currentUser}
                        showCreate={true}
                        activeUsers={activeUsers}
                    />
                ) : menuPage === 4 ? (
                    "My Checkins"
                ) : menuPage === 5 && (
                    <Logo 
                        height={200}
                    />
                ) }
            </Modal.Body>
        </Modal>
    )
};

export default AppMenu;