
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import LicenseLookup from '../modals/LicenseLookup';
import Moment from 'react-moment';
import ViewNet from '../Nets/ViewNet';
import TableContainerReverseSort from '../table/TableContainerReverseSort';
import MyLogs from '../Logs/MyLogs';

const Home = ({socket, currentUser, nets, setNets, activeUsers, setActiveUsers}) => {

    const [ loadUsers, setLoadUsers ] = useState(true);
    const [ loading, setLoading ] = useState(true);
    const [ activeNet, setActiveNet ] = useState({
        show: false,
        data: {}
    });

    useEffect(() => {
        if (currentUser.isAuthenticated && loadUsers) {
            const jsonUser = JSON.parse(localStorage.getItem('user'));
            
            socket.emit('fetch_active', jsonUser._id);
    
            const handleFetchedActive = (data) => {
                setActiveUsers(data);
            };
            socket.on('fetched_active', handleFetchedActive);
    
            setLoadUsers(false);
    
            // Cleanup listeners
            // return () => {
            //     socket.off('fetched_active', handleFetchedActive);
            //     socket.off('nets_loaded', handleNetsLoaded);
            // };
        }
    }, [loadUsers, currentUser, socket, setActiveUsers]);

    useEffect(() => {
        if(loading){
            socket.emit('load_nets');
    
            const handleNetsLoaded = (data) => {
                setNets(data);
                setLoading(false);
            };
            socket.on('nets_loaded', handleNetsLoaded);
        }
    }, [socket, loading, setNets]);
    
    useEffect(() => {
        const handleNetActive = (data) => {
            setNets([...nets, data]);
        };
    
        socket.on('net_active', handleNetActive);
    
        // Cleanup listener
        return () => {
            socket.off('net_active', handleNetActive);
        };
    }, [socket, nets, setNets]);

    useEffect(() => {
        socket.on('net_ended', data => {
            setNets((prevNets) => prevNets.filter((a) => a._id !== data));
        });
    },[socket, setNets]);

    const rowClick = e => {
        setActiveNet({show: true, data: e.original});
    }

    const columns = useMemo(() => [
        {
            Header: 'Net Name',
            accessor: 'name',
            disableFilters: false,
            descending: true,
        },
        {
            Header: 'NCS',
            accessor: 'ncs',
            disableFilters: false,
            descending: true,
            // Cell: e => 
            //     (e.row.values.ncs) ? (
            //         <LicenseLookup
            //             socket={socket}
            //             callsign={e.row.values.ncs}
            //         />
            //     ) : (
            //         'N/A'
            //     )
        },
        {
            Header: 'Frequency',
            accessor: 'freq',
            disableFilters: false,
            descending: true,
        },
        {
            Header: 'Band',
            accessor: 'band',
            disableFilters: false,
            descending: true,
        },
        {
            Header: 'Mode',
            accessor: 'mode',
            disableFilters: false,
            descending: true,
        },
        {
            Header: "Start Time",
            accessor: "startTime",
            Cell: e => {
                return(
                    <Moment format='MM-DD-YYYY HH:mm:ss'>{e.row.original.startTime}</Moment>
                )
            }
        },
    ], []);

    return (
        <>
            <Row className='mt-3'>
                <Col>
                    <TableContainerReverseSort
                        data={nets.filter(a => !a.netClosed)}
                        columns={columns}
                        heading={"Active Nets"}
                        className="shadow shadow-lg"
                        clickTrigger={rowClick}
                        tableColor={(currentUser.isAuthenticated && currentUser.user.darkMode) && 'dark'}
                    />
                </Col>
            </Row>
            {currentUser.isAuthenticated && (
                <MyLogs 
                    socket={socket}
                    currentUser={currentUser}
                    excludeClosed={true}
                    activeUsers={activeUsers}
                />
            )}
            {activeNet.show && (
                <ViewNet 
                    socket={socket}
                    currentUser={currentUser}
                    activeNet={activeNet}
                    setActiveNet={setActiveNet}
                    activeUsers={activeUsers}
                />
            )}
        </>
    );
    
};

export default Home