import React from 'react';
import { Image } from 'react-bootstrap';
import logo from '../../QSOLive.webp';

const Logo = ({ height }) => {

    return (
        <Image src={logo} fluid thumbnail style={{height}} />
    );
};

export default Logo;
