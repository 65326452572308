import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import Home from './pages/Home';
import { Col, Container, Row } from 'react-bootstrap';
import TopBar from './navs/TopBar2';
import Error from './modals/Error';
import ViewNet from './Nets/ViewNet';
import FileBar from './navs/FileBar';
import ChatModal from './modals/Chat';

const Main = () => {
    const ENDPOINT = process.env.REACT_APP_SOCKET_URL;
    const socket = io(ENDPOINT, { transports: ['websocket'] });
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [userChecked, setUserChecked] = useState(false);
    const [activeUsers, setActiveUsers] = useState([]);
    const [currentUser, setCurrentUser] = useState({
        isAuthenticated: false,
        adminSettings: false,
        user: {}
    });
    const [error, setError] = useState({
        show: false,
        type: "",
        message: ""
    });
    const [nets, setNets] = useState([]);

    const setBackgroundColor = (color) => {
        document.documentElement.style.setProperty('--app-background-color', color);
    };

    useEffect(() => {
        if (loading && !userChecked) {
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
                const jsonUser = JSON.parse(storedUser);
                socket.emit('user check', { _id: jsonUser._id });
                socket.on('checked user', (data) => {
                    localStorage.setItem('user', JSON.stringify(data));
                    setCurrentUser({
                        ...currentUser,
                        user: data,
                        isAuthenticated: true
                    });
                    setBackgroundColor(data.darkMode ? 'black' : 'white');
                    setLoading(false);
                });

                socket.on('null user', () => {
                    localStorage.removeItem('user');
                    setCurrentUser({
                        isAuthenticated: false,
                        adminSettings: false,
                        user: {}
                    });
                    setLoading(false);
                });

                setUserChecked(true);
            } else {
                setCurrentUser({ ...currentUser, isAuthenticated: false });
                localStorage.removeItem('user');
                setLoading(false);
            }
        }
    }, [socket, currentUser, loading, userChecked]);

    useEffect(() => {
        const errorListener = (error) => {
            setError({ message: error.message, type: error.type, show: true });
        };

        socket.on('error', errorListener);

        return () => {
            socket.off('error', errorListener);
        };
    }, [socket]);

    useEffect(() => {
        const userUpdatedListener = (data) => {
            setCurrentUser({ ...currentUser, user: data });
        };

        socket.on('user updated', userUpdatedListener);

        return () => {
            socket.off('user updated', userUpdatedListener);
        };
    }, [socket, currentUser]);

    useEffect(() => {
        const updateDarkMode = (isDarkMode) => {
            if (isDarkMode) {
                document.body.classList.add('dark-mode');
            } else {
                document.body.classList.remove('dark-mode');
            }
        };

        updateDarkMode(currentUser.user.darkMode);
    }, [currentUser.user.darkMode]);

    const handleLogout = (e) => {
        e.preventDefault();
        localStorage.removeItem('user');
        setCurrentUser({
            isAuthenticated: false,
            adminSettings: false,
            user: {}
        });
        socket.emit('user logout', currentUser.user);
        navigate('/', { replace: true });
    };

    return (
        <>
            <FileBar 
                socket={socket}
                currentUser={currentUser}
                setCurrentUser={setCurrentUser}
                handleLogout={handleLogout}
                activeUsers={activeUsers}
                setActiveUsers={setActiveUsers}
                nets={nets}
                setNets={setNets}
            />
            {/* <TopBar 
                socket={socket}
                currentUser={currentUser}
                setCurrentUser={setCurrentUser}
                handleLogout={handleLogout}
                activeUsers={activeUsers}
                setActiveUsers={setActiveUsers}
                nets={nets}
                setNets={setNets}
            /> */}
            <Container fluid>
                <Row>
                    <Col md={12} sm={12}>
                        <Routes>
                            <Route exact path='/' element={
                                <Home 
                                    currentUser={currentUser}
                                    socket={socket}
                                    nets={nets}
                                    setNets={setNets}
                                    activeUsers={activeUsers}
                                    setActiveUsers={setActiveUsers}
                                />
                            } />
                            <Route exact path='/:id' element={
                                <ViewNet 
                                    currentUser={currentUser}
                                    socket={socket}
                                />
                            } />
                        </Routes>
                    </Col>
                </Row>
            </Container>
            {currentUser.isAuthenticated && (
                <ChatModal 
                    currentUser={currentUser}
                    users={activeUsers}
                    socket={socket}
                />
            )}
            <Error
                error={error}
                setError={setError}
            />
        </>
    );
};

export default Main;
