import './App.css';
import React, { Fragment } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Main from './components/Main';

const App = () => {
  return (
    <Fragment>
      <Router>
        <Main />
      </Router>
    </Fragment>
  );
};

export default App;
