import React from "react"
import { Container, Table } from "react-bootstrap"
import { useTable, useSortBy, useFilters, usePagination } from "react-table"
import { DefaultColumnFilter, Filter } from "./Filter";
import TablePagination from "./Pagination";

const TableContainerReverseSort = ({ columns, data, heading, clickTrigger, tableColor }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable(
        {
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter },
            initialState: { 
                sortBy: [
                    {
                        id: 'timestamp',
                        desc: true
                    }
                ],
                pageIndex: 0, 
                pageSize: 10,
            }
        }, 
        useFilters,
        useSortBy,
        usePagination
    );

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event));
  }
  
  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page);
  }
    return (
        <>
            <Table striped bordered hover variant={tableColor} size="sm" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th style={{maxWidth: "70px"}} {...column.getHeaderProps()}>
                                    <div {...column.getSortByToggleProps()}>
                                        {column.render("Header")}
                                        {generateSortingIndicator(column)}
                                    </div>
                                    <Filter column={column} />
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row)
                        return (
                            <tr onClick={clickTrigger ? () => clickTrigger(row) : null} {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
                {data.length > 10 && (
                    <TablePagination 
                        pageOptions={pageOptions}
                        pageCount={pageCount}
                        pageIndex={pageIndex}
                        pageSize={pageSize}
                        canPreviousPage={canPreviousPage}
                        previousPage={previousPage}
                        gotoPage={gotoPage}
                        nextPage={nextPage}
                        canNextPage={canNextPage}
                        onChangeInInput={onChangeInInput}
                        onChangeInSelect={onChangeInSelect}
                    />
            )}
        </>
    )
}

export default TableContainerReverseSort