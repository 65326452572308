import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import TableContainerReverseSort from '../table/TableContainerReverseSort';
import NewLog from './NewLog';
import ViewLog from './ViewLog';

const MyLogs = ({currentUser, socket, excludeClosed, showCreate, activeUsers}) => {
    const [loading, setLoading] = useState(true);
    const [logs, setLogs] = useState([]);
    const [newLog, setNewLog] = useState(false);
    const [activeLog, setActiveLog] = useState({
        show: false,
        data: null
    });

    const rowClick = e => {
        setActiveLog({show: true, data: e.original});
    }

    useEffect(() => {
        if (loading) {
            socket.emit('load_logs', { userId: currentUser.user._id });
            socket.on('logs_loaded', async data => {
                setLogs(await data);
                setLoading(false);
            });
        }

        socket.on('log_created', data => {
            setLogs([...logs, data]);
        });

        return () => {
            socket.off('logs_loaded');
            socket.off('log_created');
        };
    }, [loading, socket, currentUser.user._id]);

    const columns = useMemo(() => [
        {
            Header: 'Log Name',
            accessor: 'name',
            disableFilters: false,
            descending: true,
        },
        {
            Header: 'Owner',
            accessor: 'creator',
            disableFilters: false,
            descending: true,
            Cell: ({ row }) => row.original.creator.username
        },
        {
            Header: 'Operators',
            accessor: 'operators',
            descending: true,
            Cell: ({ row }) => row.original.operators.map(op => op.call).join(', ')
        },
        {
            Header: 'Ended',
            accessor: 'endTimestamp',
            descending: true,
        }
    ], []);

    return (
        <>
            <Row className='mb-2 text-white'>
                {showCreate && (
                    <>
                        <Col><h4>My Logs</h4></Col>
                        <Col>
                            <div className='d-flex justify-content-end'>
                                <NewLog 
                                    socket={socket}
                                    currentUser={currentUser}
                                />
                            </div>
                        </Col>
                    </>
                )}
            </Row>
            <TableContainerReverseSort
                tableColor={(currentUser.isAuthenticated && currentUser.user.darkMode) && 'dark'}
                columns={columns}
                data={excludeClosed ? logs.filter(a => !a.logClosed) : logs}
                clickTrigger={rowClick}
            />
            {activeLog.show && (
                <ViewLog 
                    socket={socket}
                    currentUser={currentUser}
                    activeLog={activeLog}
                    setActiveLog={setActiveLog}
                    activeUsers={activeUsers}
                />
            )}
        </>
    );
};

export default MyLogs;
