
import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Col, Form, InputGroup, Row } from 'react-bootstrap';
import Moment from 'react-moment';

const UserMenu = ({handleLogout, currentUser, setCurrentUser, socket, handleClose}) => {

    const handleDarkMode = async e => {
        let darkMode = currentUser.user.darkMode;
        await setCurrentUser({...currentUser, user: {...currentUser.user, darkMode: await e}});
        await socket.emit('update user', currentUser.user);
    }

    const handleUpdateUser = e => {
        setCurrentUser({...currentUser, user: {...currentUser.user, [e.target.name]: e.target.value}});
    }

    const handleSubmitUpdateUser = () => {
        socket.emit('update user', currentUser.user);
        handleClose();
    }

    const handleUpdateLicenseData = () => {
        socket.emit('refresh_license', {callsign: currentUser.user.username});
    }

    const updateLicenseDisabled = () => {
        const updatedAtDate = new Date(currentUser.user.license.updatedAt);
        const nowDate = new Date();
        updatedAtDate.setTime(updatedAtDate.getTime() + 24 * 60 * 60 * 1000);
        if (updatedAtDate.toDateString() < nowDate.toDateString()) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <>
            <Row>
                <Col>
                    <ButtonGroup className="me-2" aria-label="First group">
                        <Button active={!currentUser.user.darkMode} variant="secondary" onClick={() => handleDarkMode(false)}>Light Mode</Button>
                        <Button active={currentUser.user.darkMode} variant="secondary" onClick={() => handleDarkMode(true)}>Dark Mode</Button>
                    </ButtonGroup>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col>
                    <InputGroup className="mb-3 shadow" onChange={handleUpdateUser}>
                        <InputGroup.Text id="basic-addon1">Callsign</InputGroup.Text>
                        <Form.Control
                            value={currentUser.user.username}
                            name='username'
                            aria-label="Callsign"
                            aria-describedby="Name-Description"
                            required
                        />
                    </InputGroup>
                </Col>
                <Col>
                    <InputGroup className="mb-3 shadow" onChange={handleUpdateUser}>
                        <InputGroup.Text id="basic-addon1">Email</InputGroup.Text>
                        <Form.Control
                            value={currentUser.user.email}
                            name='email'
                            aria-label="email"
                            aria-describedby="Name-Description"
                            required
                        />
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <InputGroup className="mb-3 shadow" onChange={handleUpdateUser}>
                        <InputGroup.Text id="basic-addon1">First Name</InputGroup.Text>
                        <Form.Control
                            value={currentUser.user.firstName}
                            name='firstName'
                            aria-label="First Name"
                            aria-describedby="Name-Description"
                            required
                        />
                    </InputGroup>
                </Col>
                <Col>
                    <InputGroup className="mb-3 shadow" onChange={handleUpdateUser}>
                        <InputGroup.Text id="basic-addon1">Last Name</InputGroup.Text>
                        <Form.Control
                            value={currentUser.user.lastName}
                            name='lastName'
                            aria-label="Last Name"
                            aria-describedby="Name-Description"
                            required
                        />
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button className='btn-block' onClick={handleSubmitUpdateUser}>Update User</Button>
                </Col>
            </Row>
            <Row className='mt-5'>
                <Col>
                    <Button disabled={updateLicenseDisabled()} onClick={handleUpdateLicenseData} variant='secondary' className='btn-block'>Update License Data</Button>
                </Col>
            </Row>
            <Row className='mt-5'>
                <Col>
                    <Button variant='danger' onClick={handleLogout}>Logout</Button>
                </Col>
            </Row>
        </>
    );
    
};

export default UserMenu