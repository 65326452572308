import React, { useEffect, useState } from 'react';
import { Alert, Button, Container, Form, Modal } from 'react-bootstrap';
import axios from 'axios';
import ForgotPassword from './ForgotPassword';
import { useNavigate } from 'react-router-dom';

const SignIn = ({setCurrentUser, currentUser, socket, show, setShow}) => {

    const ENDPOINT = process.env.REACT_APP_API_URL;

    const navigate = useNavigate();

    const [data, setData] = useState({
        username: "",
        password: "",
    });

    const [ error, setError ] = useState(null);

    const [ forgotPassword, setForgotPassword ] = useState(false);

    const handleSubmit = e => {
        e.preventDefault();
        axios.post(`${ENDPOINT}/auth/login`, data)
        .then(res => {
            window.localStorage.setItem("user", JSON.stringify(res.data));
            setCurrentUser({user: res.data, isAuthenticated: true});
            setShow(false);
        })
        .catch(error => {
            console.log(error);
            setError(error.response.data.message);
        });
    }

    return (
        <>
            <Container className='mt-2'>
                <Form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label className="form-label">Callsign</label>
                        <input type="text" name="username" onChange={e => {setData({...data, username: e.target.value.toUpperCase()})}} value={data.username} className="form-control shadow" required />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Password</label>
                        <input type="password" name="password" onChange={e => {setData({...data, password: e.target.value})}} value={data.password} className="form-control shadow" required />
                    </div>
                    { error && (
                        <div className="mb-3">
                            <Alert className='small-alert' variant='danger'>{error}</Alert>
                        </div>
                    )}
                    <div className='d-flex justify-content-center'>
                        <Button type='submit' className='btn-block'>Sign In</Button>
                    </div>
                </Form>
                <ForgotPassword 
                    existingUsername={data.username}
                    forgotPassword={forgotPassword}
                    setForgotPassword={setForgotPassword}
                    socket={socket}
                />
            </Container>
        </>
    )
}

export default SignIn