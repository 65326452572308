import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

const NewEntry = ({ socket, currentUser, activeNet, checkins }) => {

    const [formData, setFormData] = useState({
        callsign: "",
        notes: ""
    });
    const [ callExists, setCallExists ] = useState(false);

    const netId = activeNet.data._id;
    const callsignRef = useRef(null);

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent the form from submitting the default way
        socket.emit('new_checkin', { ...formData, netId });
        setFormData({
            callsign: "",
            notes: ""
        });
        callsignRef.current.focus(); // Return focus to the callsign field
    }

    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                handleSubmit(event);
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [formData]); // Added formData as a dependency to reapply the listener on formData change

    useEffect(() => {
        callsignRef.current.focus(); // Set the cursor to the callsign field on initial load
    }, []);

    const handleFormData = e => {
        if (e.target.name === "callsign") {
            const callsignExists = checkins.some(checkin => checkin.license && checkin.license.call.toUpperCase() === e.target.value.toUpperCase());
            if (callsignExists) {
                setCallExists(true);
            } else {
                setCallExists(false);
            }
        }
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <>
            <Form onSubmit={handleSubmit} className="d-flex justify-content-between align-items-center mb-2">
                <Row>
                    <Col xs="auto">
                        <Form.Group controlId="formCallsign" className='mb-1'>
                            <Form.Label sronly>
                                {callExists ? (
                                    <span className='existing-checkin'>Callsign exists in log</span>
                                ) : (
                                    <span>Callsign</span>
                                )}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={formData.callsign.toUpperCase()}
                                name='callsign'
                                onChange={handleFormData}
                                required
                                placeholder="Callsign"
                                ref={callsignRef} // Attach the ref to the callsign input
                            />
                        </Form.Group>
                        
                    </Col>
                    <Col xs="auto">
                        <Form.Group controlId="formNotes">
                            <Form.Label sronly>Notes</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={formData.notes}
                                name='notes'
                                onChange={handleFormData}
                                cols={49}
                                placeholder="Notes"
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default NewEntry;
