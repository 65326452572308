import React, { useEffect, useMemo, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import TableContainerReverseSort from '../table/TableContainerReverseSort';
import NewEntry from './NewEntry';
import Moment from 'react-moment';
import LicenseLookup from '../modals/LicenseLookup';
import DecisionCheck from '../modals/DecisionCheck';
import NetSettings from './NetSettings';

const ViewNet = ({ socket, currentUser, activeNet, setActiveNet, activeUsers }) => {

    const [loading, setLoading] = useState(true);
    const [checkins, setCheckins] = useState([]);
    const [proceedToClose, setProceedToClose] = useState(false);
    const [showDecision, setShowDecision] = useState(false);

    useEffect(() => {
        if (loading) {
            socket.emit('load_checkins', { netId: activeNet.data._id });

            const handleCheckinsLoaded = async (data) => {
                setCheckins(await data);
                setLoading(false);
            };

            socket.on('checkins_loaded', handleCheckinsLoaded);

            return () => {
                socket.off('checkins_loaded', handleCheckinsLoaded);
            };
        }
    }, [loading, activeNet.data._id, socket]);

    useEffect(() => {
        if(proceedToClose){
            socket.emit('end_net', activeNet.data._id);
            setProceedToClose(false);
            setActiveNet({...activeNet, show:false});
        }
    }, [proceedToClose, socket, activeNet, setActiveNet]);

    useEffect(() => {
        const handleCheckinReceived = (data) => {
            setCheckins(prevCheckins => prevCheckins.filter(a => a._id !== data._id).concat(data));
        };

        socket.on('checkin_received', (data) => {
            if (data.netId === activeNet.data._id) {
                handleCheckinReceived(data._doc);
            }
        });

        return () => {
            socket.off('checkin_received', handleCheckinReceived);
        };
    }, [activeNet.data._id, socket]);

    useEffect(() => {
        socket.on('ncs_added', data => {
            if(data.net_id === activeNet.data._id){
                setActiveNet({...activeNet, data: {...activeNet.data, allowedNcs: data.allowedNcs}});
            }
        });
    }, [activeNet, socket, setActiveNet]);

    const columns = useMemo(() => [
        {
            Header: 'Callsign',
            accessor: 'call',
            disableFilters: false,
            descending: true,
            // Cell: e => <span>{e.row.original.license.call}</span>
            Cell: e => 
                (e.row.original.license) ? (
                    <LicenseLookup
                        socket={socket}
                        callsign={e.row.original.license ? e.row.original.license.call : "NOT_FOUND" }
                    />
                ) : (
                    'N/A'
                )
        },
        {
            Header: 'Name',
            accessor: 'fname',
            disableFilters: false,
            descending: true,
            Cell: e => {
                const fname = e.row.original.license ? e.row.original.license.fname : "NOT_FOUND";
                const formattedFname = fname.charAt(0).toUpperCase() + fname.slice(1).toLowerCase();
                return <span>{formattedFname}</span>;
            }
        },
        {
            Header: 'Location',
            accessor: 'addr2',
            disableFilters: false,
            descending: true,
            Cell: e => {
                if (e.row.original.license) {
                    return (
                        <div>
                            <div>{e.row.original.license.addr2.toUpperCase()}, {e.row.original.license.state.toUpperCase()},</div>
                            <div>{e.row.original.license.country.toUpperCase()}</div>
                        </div>
                    );
                } else {
                    return <div>NOT_FOUND</div>;
                }
            }
        },
        {
            Header: 'Notes',
            accessor: 'notes',
            disableFilters: false,
            descending: true,
            Cell: e => (
                <div style={{ display: 'flex', width: '400px', maxHeight: '72px', overflow: 'hidden' }}>
                    <span style={{ width: '100%', overflowY: 'auto', wordWrap: 'break-word' }}>
                        {e.value}
                    </span>
                </div>
            )
        },
        {
            Header: 'Date/Time',
            accessor: 'createdAt',
            disableFilters: false,
            descending: true,
            Cell: e => <Moment format='MM/d/YY HH:mm:ss'>{e.value}</Moment>
        },
    ], [socket]);

    const uniqueCheckins = useMemo(() => {
        const seen = new Set();
        return checkins.filter(checkin => {
            const duplicate = seen.has(checkin.license ? checkin.license.call : "NOT_FOUND");
            seen.add(checkin.license ? checkin.license.call : "NOT_FOUND");
            return !duplicate;
        });
    }, [checkins]);

    const duplicateCheckins = useMemo(() => {
        const seen = new Set();
        return checkins.filter(checkin => {
            const duplicate = seen.has(checkin.license ? checkin.license.call : "NOT_FOUND");
            seen.add(checkin.license ? checkin.license.call : "NOT_FOUND");
            return duplicate;
        });
    }, [checkins]);

    const userAllowed = () => {
        if (!currentUser.isAuthenticated) {
            return false;
        } else if(currentUser.isAuthenticated && (currentUser.user.username === activeNet.data.logger || currentUser.user.username === activeNet.data.ncs)){
            return true;
        } else {
            return activeNet.data.allowedNcs.some(ncs => ncs.value === currentUser.user.username);
        }
    };
    

    return (
        <>
            <Modal show={activeNet.show} fullscreen={true} onHide={() => setActiveNet({ ...activeNet, show: false })}>
                <Modal.Header closeButton>
                    <Container fluid>
                    <Row className='d-flex align-items-center'>
                        <Col>
                            <Row className='text-center'>
                                <Col>
                                    <h3 className='text-center'>{activeNet.data.name}</h3>
                                </Col>
                                <Col>
                                    <span>Unique Checkins: <div>{uniqueCheckins.length}</div></span>
                                </Col>
                                <Col>
                                    <span>Duplicate Checkins: <div>{duplicateCheckins.length}</div></span>
                                </Col>
                                <Col>
                                    <span>Total Entries: <div>{checkins.length}</div></span>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="auto" className='ml-auto'>
                            <NetSettings 
                                userAllowed={userAllowed}
                                activeNet={activeNet}
                                setActiveNet={setActiveNet}
                                socket={socket}
                                currentUser={currentUser} 
                                loading={loading}
                                activeUsers={activeUsers}
                            />
                        </Col>
                    </Row>
                    </Container>
                </Modal.Header>
                <Modal.Body>
                {userAllowed() && (
                    <Row>
                        <Col className='d-flex justify-content-center align-items-center'>
                            <NewEntry
                                activeNet={activeNet}
                                checkins={checkins}
                                setCheckins={setCheckins}
                                socket={socket}
                            />
                        </Col>
                    </Row>
                )}
                    <Row>
                        <Col>
                            <TableContainerReverseSort
                                columns={columns}
                                data={checkins}
                                tableColor={'dark'}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <DecisionCheck 
                title={"Close Net"}
                body={"Are you sure you wish to close this net?"}
                show={showDecision}
                setShow={setShowDecision}
                handler={proceedToClose}
                setHandler={setProceedToClose}
            />
        </>
    );

};

export default ViewNet;
