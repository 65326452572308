import axios from 'axios';
import React, { useState } from 'react';
import { Alert, Container, Form } from 'react-bootstrap';
// import ViewDoc from '../Modals/ViewDoc';

const SignUp = ({ setCurrentUser, show, setShow }) => {

    const ENDPOINT = process.env.REACT_APP_API_URL;

    const [ currentDoc, setCurrentDoc ] = useState({
        show: false,
        noBuffer: true,
        data: {
            fileName: "TOS.pdf"
        }
    });

    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: null,
        password2: null,
        tos: false,
    });

    const handleSubmit = e => {
        e.preventDefault();
        axios.post(`${ENDPOINT}/auth/new`, formData)
        .then(res => {
            let json = JSON.stringify(res.data);
            window.localStorage.setItem("user", json);
            setCurrentUser({user: res.data, isAuthenticated: true});
            setShow(false);
        })
        .catch(error => {
            console.log(error);
        });
    }

    const { username, email, password, password2, tos } = formData;

    return (
        <Container className='mt-2'>
            <Form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="mb-3 col-md-6 col-sm-12">
                        <label className="form-label">Callsign</label>
                        <input onChange={e => setFormData({...formData, username: e.target.value.toUpperCase()})} type="text" name="username" value={username} className="form-control shadow" required />
                    </div>
                    <div className="mb-3 col-md-6 col-sm-12">
                        <label className="form-label">Email address</label>
                        <input onChange={e => setFormData({...formData, email: e.target.value})} type="email" name="email" value={email} className="form-control shadow" required />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="mb-3 col-md-6 col-sm-12">
                        <label className="form-label">Password</label>
                        <input onChange={e => setFormData({...formData, password: e.target.value})} type="password" name="password" value={password} className="form-control shadow" required />
                    </div>
                    <div className="mb-3 col-md-6 col-sm-12">
                        <label className="form-label">Confirm Password</label>
                        <input onChange={e => setFormData({...formData, password2: e.target.value})} type="password" name="password2" value={password2} className="form-control shadow" required />
                    </div>
                    
                    <div className='row'>
                        <div className="col d-flex justify-content-center">
                            {password && password2 && password.length > 6 && password !== password2 ? (
                                <Alert className='small-alert' variant='danger'>Passwords do not match!</Alert>
                            ) : password && password2 && password.length > 6 && password === password2 ? (
                                <Alert className='small-alert' variant='success'>Passwords match!</Alert>
                            ) : (
                                <Alert className='small-alert' variant='primary'>Password should be at least 7 characters.</Alert>
                            )}
                        </div>
                    </div>
                </div>
                <hr />
                <div>
                    <Form.Check 
                        type="switch"
                        id="custom-switch"
                        label={
                            formData.tos ? "Accepted Terms of Service" : 
                            <button type='button' onClick={() => setCurrentDoc({...currentDoc, show: true})} className='no-button cursor-pointer button-link'>
                                Terms of Service
                            </button>
                        }
                        value={tos}
                        onClick={() => {setFormData({...formData, tos: !tos})} }
                    />
                </div>
                <br />
                <div className="text-center d-flex justify-content-center">
                    <button 
                        className="g-recaptcha btn btn-block btn-primary shadow" 
                        type='submit' 
                        disabled={(!password || !password2 || !username || !tos) || password !== password2}
                    >Submit</button>
                </div>
            </Form>
            {/* {<ViewDoc 
                currentDoc={currentDoc}
                setCurrentDoc={setCurrentDoc}
                socket={socket}
            />} */}
        </Container>
    )
}

export default SignUp