
import React, { useMemo } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import TableContainerReverseSort from '../table/TableContainerReverseSort';
import NewNet from './NewNet';

const MyNets = ({currentUser, nets, socket, setMenuOpen, handleNewNet, newNet, setNewNet}) => {

    const handleRestartNet = e => {
        socket.emit('restart_net', {netId: e});
        setMenuOpen(false);
    }

    let columns = useMemo(() => [
        {
            Header: 'Name',
            accessor: 'name',
            disableFilters: false,
            descending: true,
        },
        {
            Header: 'Beginning',
            accessor: 'startTime',
            disableFilters: false,
            descending: true,
        },
        {
            Header: 'Ending',
            accessor: 'endTime',
            disableFilters: false,
            descending: true,
        },
        {
            Header: 'Status',
            accessor: 'test',
            disableFilters: true,
            descending: true,
            Cell: e => {
                let netRunning = false;
        
                nets.forEach(net => {
                    if (e.row.original.name === net.name && !net.netClosed) {
                        netRunning = true;
                    }
                });
        
                if (netRunning) {
                    return "In Progress";
                } else {
                    return <Button onClick={() => handleRestartNet(e.row.original._id)}>Start</Button>;
                }
            }
        }
        
    ]);

    return (
        <>
            <Row className='mb-2 text-white'>
                <Col><h4>My Nets</h4></Col>
                <Col>
                    <div className='d-flex justify-content-end'>
                        <NewNet 
                            handleNewNet={handleNewNet}
                            newNet={newNet}
                            setNewNet={setNewNet}
                        />
                    </div>
                </Col>
            </Row>
            <TableContainerReverseSort
                tableColor={(currentUser.isAuthenticated && currentUser.user.darkMode) && 'dark'}
                columns={columns}
                data={
                    nets.filter(a => (
                        a.ncs === currentUser.user.username || 
                        a.logger === currentUser.user.username || 
                        a.allowedNcs.some(a => a.value === currentUser.user.username))
                    )
                }
            />
        </>
    );
    
};

export default MyNets