import React, { useState } from 'react';
import {Button, Col, Form, InputGroup, Modal, Row} from 'react-bootstrap';
import Select from 'react-select';
import bandsAndFreqs from '../../bandsAndFreqs.json'

const NewNet = ({newNet, setNewNet, handleNewNet}) => {

    const [ show, setShow ] = useState(false);
    const [ ncs, setNcs ] = useState(null);
    const [ logger, setLogger ] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const availableModes = bandsAndFreqs.ham_radio_bands.find(band => band.band === newNet.band) ?. modes_allowed || [];
    
    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                New Net
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col md="6">
                                <InputGroup className="mb-3 shadow">
                                    <InputGroup.Text id="basic-addon1" aria-required>Net Name</InputGroup.Text>
                                    <Form.Control
                                        onChange={e => setNewNet({...newNet, name: e.target.value})}
                                        aria-label="Net Name"
                                        aria-describedby="Name-Description"
                                        required
                                    />
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup className="mb-3 shadow">
                                    <InputGroup.Text id="basic-addon1">Frequency(MHz)</InputGroup.Text>
                                    <Form.Control
                                        onChange={e => setNewNet({...newNet, freq: e.target.value})}
                                        aria-label="Net Name"
                                        aria-describedby="Name-Description"
                                        required
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                <InputGroup className="mb-3 shadow" onChange={e => setNewNet({...newNet, ncs: e.target.value.toUpperCase()})}>
                                    <InputGroup.Text id="basic-addon1">NCS Callsign</InputGroup.Text>
                                    <Form.Control
                                        value={ncs}
                                        aria-label="NCS Call"
                                        aria-describedby="Name-Description"
                                        required
                                    />
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup className="mb-3 shadow" onChange={e => setNewNet({...newNet, logger: e.target.value.toUpperCase()})}>
                                    <InputGroup.Text id="basic-addon1">Logger Callsign</InputGroup.Text>
                                    <Form.Control
                                        value={logger}
                                        aria-label="Logger Call"
                                        aria-describedby="Name-Description"
                                        required
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Select
                                    className='text-black shadow'
                                    placeholder="Band"
                                    required
                                    onChange={e => setNewNet({...newNet, band: e.value})}
                                    options={bandsAndFreqs.ham_radio_bands.map(band => ({
                                        label: band.band,
                                        value: band.band
                                    }))}
                                />
                            </Col>
                            <Col>
                                <Select 
                                    className='text-black shadow'
                                    placeholder="Mode"
                                    onChange={e => setNewNet({...newNet, mode: e.value})}
                                    required
                                    options={availableModes.map(mode => ({
                                        label: mode,
                                        value: mode
                                    }))}
                                />
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Row className='mt-3'>
                        <Col>
                            <Button onClick={handleNewNet} type='submit' className='btn-block shadow'>Activate Net</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default NewNet;
